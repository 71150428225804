import React from 'react';

// Customizable Area Start
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
  Grid,
  Button,
  Badge,
  Tab,
  Tabs,
} from '@mui/material';
import { ArrowBack, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { HeaderLogoImg } from '../../../blocks/landingpage/src/assets';
import CardActionArea from '@material-ui/core/CardActionArea';
import PageNotFound from "../../../components/src/PageNotFound.web";
import { Morning, Afternoon, Night, ActiveMorning, ActiveAfternoon, ActiveNight } from "./assets";
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from 'moment';

// Customizable Area End

import ProvidersController, {
  Props,
} from "./ProvidersController";
import { Card, CardContent } from '@material-ui/core';
class Providers extends ProvidersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start


    dayjs.extend(updateLocale);

    dayjs.updateLocale('en', {
      weekStart: 1,
    });

    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    });

    const dayOfWeekFormatter = (dayOfWeek: any) => {
      const weekdaysFull = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return weekdaysFull[dayOfWeek];
    };
    return (
      <StyledWrapper>
        <Box className='leftLine'></Box>
        <>
          {this.state.message ? <PageNotFound /> :
            <section className="containerStyles">
              <Box className="header" style={{ borderBottom: "1px solid #E7E5E4" }}>
                <img className="logoImg" src={HeaderLogoImg} alt="HeaderLogoImg" />
              </Box>

              <Box className="content">
                <Box className="leftSide">
                  <Box className="stepper-width">
                    <Stepper alternativeLabel activeStep={this.state.activeStep}>
                      {this.state.steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  <Box className='padding-class back-box' sx={webStyles.chooseProvider}>
                    <Box onClick={this.goBack} data-test-id="goBackTest" style={{ display: "flex", flexDirection: "row", gap: '12px', alignItems: 'center', padding: "10px 16px", border: "2px solid #E7E5E4", borderRadius: "16px" }}>
                      <ArrowBack style={{ color: '#A8A29E', width: 16 }} />
                      <Typography style={{ color: '#A8A29E', fontSize: 20, fontWeight: 700, lineHeight: 1, position: 'relative', top: 2, fontFamily: "Raleway" }}>
                        Back
                      </Typography>
                    </Box>
                    <Typography style={{ fontWeight: 500, fontSize: 29, fontFamily: "Poppins" }}>Choose your Provider</Typography>
                    <Box className='filtericon'>
                      <Box data-test-id="HandleFilterTest" onClick={() => this.setState({ openFilter: !this.state.openFilter })}>
                        <Badge badgeContent={1} >
                          <FilterListIcon />
                        </Badge>
                      </Box>
                      {this.state.openFilter && <Box sx={{ position: "absolute", right: "0px", zIndex: 99999, marginTop: "8px" }}>
                        <Card style={webStyles.filterCard}>
                          <CardActionArea>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: '20px', textAlign: "start", padding: '14px 10px', borderBottom: '1px solid #E7E5E4' }}>

                              <CardContent style={{ padding: "16px 8px 16px 16px" }}>

                                <Typography style={webStyles.LMFT}>
                                  Specialities
                                </Typography>
                                <Box sx={webStyles.disease1}>
                                  <Typography style={webStyles.specialities}>
                                    Anxiety
                                  </Typography>
                                  <Typography style={webStyles.specialities}>
                                    Depression
                                  </Typography>
                                  <Typography style={webStyles.specialities}>
                                    Addiction
                                  </Typography>
                                  <Typography style={webStyles.specialities}>
                                    Trauma
                                  </Typography>
                                  <Typography style={webStyles.specialities}>
                                    Grief and Loss
                                  </Typography>
                                  <Typography style={webStyles.specialities}>
                                    Chronic Pain
                                  </Typography>
                                  <Typography style={webStyles.specialities}>
                                    Cognitive Decline
                                  </Typography>
                                  <Typography style={webStyles.specialities}>
                                    Other
                                  </Typography>
                                </Box>
                                <Box style={{display:"flex",justifyContent:"space-between"}}>
                                <Typography style={{ padding: "16px 8px 16px 16px" }} >Genders</Typography>
                                <KeyboardArrowDown className='genderoption' color='disabled'/>
                                </Box>
                              </CardContent>

                            </Box>
                          </CardActionArea>
                        </Card>
                      </Box>}
                    </Box>
                  </Box>
                  <Box className='padding-class' sx={{ display: "flex", flexDirection: "row", gap: "9px", justifyContent: "center", paddingBottom: 3, paddingLeft: "112px", maxWidth: "811px", width: "100%", margin: '0 auto' }}>
                    <Typography style={{ fontWeight: 400, fontSize: "16px", fontFamily: "Poppins" }}>Sort by:</Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                      <Typography style={{ fontWeight: 700, fontSize: "16px", fontFamily: "Poppins" }}>Recommended </Typography>
                      <KeyboardArrowDown />
                    </Box>
                  </Box>
                  <Grid className='padding-class' style={{ justifyContent: 'center', gap: 24, overflow: "scroll", paddingLeft: "112px" }} container spacing={1}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].slice(0, this.state.showCards).map((index) => {
                      return <Card style={webStyles.root}>
                        <CardActionArea>
                          <Box sx={{ display: "flex", flexDirection: "row", gap: '20px', textAlign: "start", padding: '14px 10px', borderBottom: '1px solid #E7E5E4' }}>
                            <img className='card-profile' style={{ minWidth: '132px', height: '132px', borderRadius: '50%' }}
                              src="https://picsum.photos/200/300" alt='profile-img'
                            />
                            <CardContent style={{ padding: 0 }}>
                              <Typography style={webStyles.cardName}>
                                Lucy Liu
                              </Typography>
                              <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
                                <Typography style={webStyles.viewProfile}>
                                  Full Profile
                                </Typography>
                                <KeyboardArrowRight style={{ height: "16px", width: "16px", color: "#E46760" }} />
                              </Box>
                              <Typography style={webStyles.LMFT}>
                                LMFT
                              </Typography>
                              <Box sx={webStyles.disease}>
                                <Typography style={{ fontWeight: "400", fontSize: "13px", padding: "4px 8px", borderRadius: "16px", border: "1px solid #E9EBED", fontFamily: "Poppins" }}>
                                  Couple Therapy
                                </Typography>
                                <Typography style={{ fontWeight: "400", fontSize: "13px", padding: "4px 8px", borderRadius: "16px", border: "1px solid #E9EBED", fontFamily: "Poppins" }}>
                                  Anxiety
                                </Typography>
                              </Box>
                              <Typography style={webStyles.sessionPrice}>
                                Initial session price:
                              </Typography>
                              <Box style={{ display: "flex", gap: "5px" }}>
                                <Typography style={{ fontWeight: 400, fontSize: "13px", color: "#292524" }}>
                                  $50
                                </Typography>
                                <Typography style={{ fontWeight: 400, fontSize: "13px", color: "#78716C" }}>
                                  (60 mins)
                                </Typography>
                              </Box>
                            </CardContent>
                          </Box>
                        </CardActionArea>
                        <Box className='card-footer' sx={webStyles.CardFooter}>
                          <Box sx={{ display: "flex", flexdirection: "row", gap: 1 }}>
                            <Typography>
                              Next availability:
                            </Typography>
                            <Typography style={{ fontWeight: 700, fontSize: "16px" }}>Aug 15</Typography>
                          </Box>
                          <Box>
                            <Typography style={webStyles.selectProvider}>Select Provider</Typography>
                          </Box>
                        </Box>
                      </Card>
                    })}
                  </Grid>
                  <Box className='padding-class' style={{ marginTop: "27px", paddingLeft: "112px" }}>
                    <Button data-test-id="loadMoreTest" onClick={this.handleLoadMore} style={{ width: "356px", backgroundColor: "#E46760", borderRadius: "24px", height: 56 }}>
                      <Typography style={{ textTransform: "capitalize", fontWeight: 700, fontSize: "20px", color: "#FFFFFF", position: 'relative', top: 3 }}>Load More</Typography>
                    </Button>
                    <Typography style={{ fontWeight: 700, fontSize: "20px", fontFamily: "Raleway", textDecoration: "underline", marginTop: "16px", color: "#78716C" }}>Join Waitlist</Typography>
                  </Box>
                </Box>
                <Box className='rightSide'>
                  <Typography className='time-box date-box' style={webStyles.chooseHeadStyle}>
                    Choose a Date
                  </Typography>
                  <Box className='time-box' style={{ display: "flex", paddingBottom: "12px", justifyContent: "center", gap: "5px", paddingLeft: 42, maxWidth: 364 }}>
                    <Typography style={{ fontWeight: 700, fontSize: "16px", color: "#A8A29E" }}>Central Time  </Typography>
                    <Typography style={{ fontWeight: 400, fontSize: "16px", color: "#A8A29E" }}> (UTC-6)</Typography>
                    <KeyboardArrowDown style={{backgroundColor:"#FFFFFF",color:"#222222", bottom: 5 }} />
                  </Box>
                  <Box style={{ borderBottom: '1px solid #D6D3D1' }}></Box>
                  <Box className='tab-parent-box' sx={{ width: '100%', margin: '24px 12px 24px 42px', maxWidth: 364 }}>
                    <Tabs
                      className='tab-inner-div'
                      data-test-id="TabTest"
                      value={this.state.selectedTab}
                      onChange={this.handleTabChange}
                      textColor="primary"
                      indicatorColor="primary"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 4.5,
                        borderRadius: '50px',
                        border: '1px solid #D6D3D1',
                        justifyContent: 'space-between',
                        padding: '4px',
                      }}
                    >
                      <Tab
                        label={<Box sx={{ display: 'flex', alignItems: 'center' ,textTransform:"capitalize" }}><img style={{ marginRight: '2px' }} src={this.state.selectedTab=== 0 ? ActiveMorning : Morning} />Morning</Box>}
                        sx={this.state.selectedTab === 0 ? webStyles.TimeZoneActive : webStyles.TimeZone}
                      />
                      <Tab
                        label={<Box sx={{ display: 'flex', alignItems: 'center' ,textTransform:"capitalize" }}><img style={{ marginRight: '2px' }} src={this.state.selectedTab=== 1 ? ActiveAfternoon :Afternoon} />Afternoon</Box>}
                        sx={this.state.selectedTab === 1 ? webStyles.TimeZoneActive : webStyles.TimeZone}
                      />
                      <Tab
                        label={<Box sx={{ display: 'flex', alignItems: 'center' ,textTransform:"capitalize" }}><img style={{ marginRight: '2px' }} src={this.state.selectedTab=== 2 ? ActiveNight :Night} />Night</Box>}
                        sx={this.state.selectedTab === 2 ? webStyles.TimeZoneActive : webStyles.TimeZone}
                      />
                    </Tabs>
                  </Box>
                  <Box className='calender-box' style={{ paddingInline: '42px' }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker open className="datePicker" label="Select Date" dayOfWeekFormatter={dayOfWeekFormatter} disablePast />
                    </LocalizationProvider>
                  </Box>
                  <Box>

                  </Box>
                </Box>

              </Box>
            </section>
          }</>
      </StyledWrapper >

    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledWrapper = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  '& .leftLine': {
    backgroundColor: "#1A4A42",
    width: 32,
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 99999,
    borderRadius: '0px 16px 16px 0px'
  },
  "& .MuiTab-root.Mui-selected":{color:"#E46760"},
  "& .MuiTabs-indicator":{display:"none"},
  '& .MuiStepLabel-label': {
    fontWeight: 700,
    fontSize: '16px !important',
    fontFamily: "Poppins",
  },
  "& .containerStyles": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
    minHeight: "78px",
    margin: "auto",
    flex: 1,
    backgroundcolor: "#F5F5F4",
  },
  "& .datePicker": {
    width: "100%",
    maxWidth: 425,
    visibility: 'hidden',
    marginTop: -50
  },
  '& .MuiStep-root.MuiStep-horizontal': {
    minWidth: 'max-content'
  },
  "& .filtericon": {
    position: "relative"
  },
  "& .filtericon .MuiSvgIcon-root": {
    backgroundColor: "#1A4A42",
    color: "#FFFFFF",
    borderRadius: "16px",
    height: "24px",
    width: "24px",
    padding: 10,
    "& .genderoption":{ backgroundColor: "#FFFFFF",
    }
  },
  "& .MuiBadge-badge": {
    color: "#E46760",
    backgroundColor: "#E46760",
    top: 3,
    right: 3,
    minWidth: 16,
    width: 16,
    height: 16
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#E46760"
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#1A4A42",
  },
  "& .contentWrapper": {
    width: '100%',
    maxWidth: "560px",
    marginTop: "55.92px",
    margin: "55.92px auto auto auto",
  },
  "& .header": {
    height: "auto",
    minHeight: "78px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff"
  },
  "& .logoImg": {
    marginLeft: "144px",
    width: "162px",
    height: "41.24px"
  },

  "& .content": {
    display: "flex",
    width: "100%",
    height: "auto",
    flex: 1
  },

  "& .leftSide": {
    height: "auto",
    width: "100%",
    maxWidth: '67%',
    textAlign: "center",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F5F5F4",
    "& .image": {
      width: "100%",
      height: "auto",
      maxWidth: "373.89px",
      maxHeight: "337.08px",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flex: 1
    },
    '& .stepper-width': {
      maxWidth: "600px",
      width: '100%',
      fontfamily: "Poppins",
      margin: "0 auto"
    }
  },

  "& .rightSide": {
    height: "auto",
    width: "100%",
    maxWidth: '33%',
    backgroundColor: '#FFF'
  },
  '& .tab-inner-div': {
    minHeight: 40,
    height: 40,
    '& > div > div': {
      justifyContent: 'space-between'}
  },
  '& .tab-inner-div button': {
    padding: '8px',
    minHeight: 40,
    height: 40
  },
  "& .secondarybtn": {
    display: "flex",
    justifyContent: "center",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    color: "#E46760",
    border: "none",
    background: "none",
    cursor: "pointer",
    marginTop: '33px'
  },
  "& .heading": {
    fontFamily: "Raleway",
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "50px",
    lineHeight: "60px",
    letterSpacing: "0.5px",
    textAlign: "center",
    marginBottom: "16px",
    marginTop: "0"
  },

  "& .paragraph": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#FFFFFF",
    margin: 0,
    "& p": {
      margin: 0
    }
  },

  "& .headingRight": {
    fontFamily: "Raleway",
    color: "#2C2524",
    fontWeight: 700,
    fontSize: "50px",
    lineHeight: "60px",
    letterSpacing: "0.5px",
    textAlign: "center",
    marginBottom: "16px",
    marginTop: "0"
  },

  "& .paragraphRight": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#2C2524",
    margin: 0,
    "& p": {
      margin: 0
    }
  },
 
  "& .button": {
    width: "100%",
    maxWidth: "540px",
    display: "flex",
    justifyContent: "center",
    textDecoration: "none",
    borderRadius: "24px",
    backgroundColor: "#E46760",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
    padding: "16.5px 0",
    border: "none",
    cursor: "pointer",
    margin: "46px auto 0",
  },

  "@media screen and (max-width: 1480px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
    "& .heading": {
      fontSize: "40px",
    },
    "& .headingRight": {
      fontSize: "40px",
    },
    "& .paragraph": {
      fontSize: "18px",
    },
    "& .paragraphRight": {
      fontSize: "18px",
    },
  },

  '@media(max-width: 1440px)': {
    '& .padding-class': {
      paddingLeft: '24px !important'
    },
    "& .logoImg": {
      paddingLeft: "24px",
    },
    '& .card-profile': {
      minWidth: '100px !important',
      width: '100px !important',
      height: '100px !important',
    },
    '& .back-box': {
      maxWidth: '94%',
    },
    '& .tab-parent-box': {
      margin: '20px 12px !important'
    },
    '& .calender-box': {
      paddingInline: '12px !important'
    },
    '& .time-box': {
      padding: 'unset !important',
      maxWidth: 'unset !important',
    },
    '& .date-box': {
      marginBlock: 32
    }
  },

  '@media (max-width: 1299px)': {
    '& .card-footer': {
      flexDirection: "column",
    },
  },
  '@media (max-width: 1170px)': {
    '& .tab-parent-box': {
      maxWidth: '284px !important'
    },
    '& .tab-inner-div button': {
      fontSize: '13px !important',
      padding: '5px !important'
    },
  },

  "@media screen and (max-width: 1100px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
    "& .heading": {
      fontSize: "38px",
    },
    "& .headingRight": {
      fontSize: "38px",
    },
    "& .paragraph": {
      fontSize: "18px",
    },
    "& .paragraphRight": {
      fontSize: "18px",
    },
  },

  "@media screen and (max-width: 991px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
    "& .heading": {
      fontSize: "26px",
    },
    "& .headingRight": {
      fontSize: "26px",
    },
    "& .paragraph": {
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.5",
    },
    "& .paragraphRight": {
      fontSize: "16px",
      lineHeight: "1.5",
      letterSpacing: "0.5",
    },
    "& .leftSide": {
      padding: "30px",
    },
  },

  "@media screen and (max-width: 767px)": {
    "& .leftSide": {
      maxWidth: "100%",
    },
    "& .content": {
      flexWrap: "wrap",
    },
    "& .button": {
      fontSize: "18px",
    },
  },
});

const webStyles = {
  root: {
    maxHeight: "442px",
    maxWidth: "384px",
    borderRadius: 16,
    width: '100%',
    boxShadow: "unset",
    border: "1px solid #D6D3D1"
  },
  filterCard: {
    maxHeight: "344px",
    maxWidth: "348px",
    width: "100%",
    borderRadius: 16,
    boxShadow: "unset",
    border: "1px solid #D6D3D1"
  },
  specialities: { fontWeight: "400", fontSize: "13px", padding: "4px 8px", borderRadius: "16px", border: "1px solid #E9EBED", fontFamily: "Poppins" },
  datePicker: { width: "100%", height: "344px" },
  TimeZone: {
    borderRadius: "50px", fontWeight: "700", fontSize: "16px", color: "#78716C", padding: "12px 10px", backgroundColor: "#F5F5F4"
    , display: "flex",
    gap: "6px",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  TimeZoneActive: {color: "#E46760", backgroundColor: "#FAE1DF", border: "1px solid #F2B3B0",
    borderRadius: "50px", fontWeight: "700", fontSize: "16px", padding: "12px 10px", display: "flex",
    gap: "6px",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  sessionPrice: { fontWeight: 700, fontSize: "13px", color: "#292524", fontFamily: "Poppins" },
  viewProfile: { color: "#E46760", fontWeight: 400, fontSize: "13px", fontFamily: "Poppins" },
  chooseProvider: { display: "flex", flexDirection: "row", justifyContent: "Space-between", paddingLeft: "112px", paddingTop: '60px', paddingBottom: '37px', maxWidth: 811, width: '100%', margin: '0 auto' },
  cardName: {
    fontWeight: 700, fontSize: "20px",
    fontFamily: "Poppins"
  },
  LMFT: { fontWeight: 700, fontSize: "13px", color: "#292524", marginTop: "8px", marginBottom: "4px", fontFamily: "Poppins" },
  disease: { display: "flex", flexWrap: 'wrap', flexDirection: "row", color: "#203447", fontWight: 400, fontSize: "13px", marginBottom: "8px", gap: "2px" },
  disease1: { marginTop: "24px", width: "320px", flexWrap: "wrap", display: "flex", color: "#203447", fontWight: 400, fontSize: "13px", marginBottom: "8px", gap: "8px" },

  CardFooter: {
    display: "flex",
    flexDirection: "row", justifyContent: 'space-between', padding: "12px 16px", alignItems: "center",
  },
  selectProvider: {
    color: "#E46760",
    border: "2px solid #E46760",
    borderRadius: "26px",
    padding: "4px 10.5px",
    height: 21,
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  chooseHeadStyle: {
    fontFamily: "Poppins",
    fontSize: "29px",
    fontWeight: 500,
    padding: "33px 77px 33px 42px",
    color: "#A8A29E",
    textAlign: "center" as "center",
    maxWidth: "20vw"
  }
};
export default Providers;
// Customizable Area End